import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[5]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[9]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        {/* seccion nueva */}
        <div className="z-[9] relative w-4/5 mx-auto md:h-[100vh] h-[130vh] flex self-center items-center justify-center md:flex-row flex-col-reverse">
          <div className="md:w-[50%] flex flex-col justify-center md:items-start items-center">
            <h1 className="text-white text-[30px] md:text-[40px] lg:text-[55px] md:text-start text-center">{rpdata?.dbSlogan?.[0].slogan}</h1>
            <p className="text-white text-center leading-7 md:text-start">{rpdata?.dbHome?.[2].text}</p>
            <ButtonContent />
          </div>
          <div className="md:w-[50%] flex justify-center md:border-0 border-b-4 border-white md:item-center items-end md:my-0 my-8">
            <img src={rpdata?.stock?.[12]} alt='no found' loading='Photo'
            className='w-[100%] md:w-[85%] h-[80%] md:h-[100%] object-cover'
            />
          </div>
        </div>
        {/* otra seccion */}
        {/* <div className="z-[9] relative w-4/5 mx-auto h-[100vh]">
          <div className="w-full h-full pb-[10%]  md:pb-[5%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center text-[30px] md:text-[40px] lg:text-[55px]">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center leading-7">{rpdata?.dbHome?.[2].text}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div> */}
      </div>
    </>
  );
}

export default HeroSection;
