import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="px-4 py-8">
        <div className="flex justify-center items-center pb-5 gap-10 flex-wrap flex-col">
          {rpdata?.gmb?.link.length > 1 ? (
            <a
              href={rpdata?.gmb?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={rpdata?.gmb?.img} alt="Not found" width={"250px"} />
            </a>
          ) : null}
          <div className="flex flex-row flex-wrap self-center gap-6">
            <a
              href="https://zillow.com/reviews/write/?s=X1-ZU16h7mvqx5d5ah_94p27"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2FZillow-Logo.png?alt=media&token=68b48a11-d9d0-43a8-9bce-3d1851dd6679"
                alt="Zillow"
                className="md:w-[250px]"
              />
            </a>

            <a
              href="https://www.bassettpremierrealty.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/Basesett.png?alt=media&token=9465045e-81c7-4bc9-a0be-c306abc30165&_gl=1*ninn17*_ga*MTg3NzIxNTgxMi4xNjczOTA2Mjk5*_ga_CW55HF8NVT*MTY4NjMzNzYxMy4zNC4wLjE2ODYzMzc2MTMuMC4wLjA."
                alt="logo_bassette"
              />
            </a>
          </div>
        </div>
        {rpdata?.dbSocialMedia?.redes.length > 0 ||
        rpdata?.dbSocialMedia?.directorios.length > 0 ? (
          <h2 className="text-center pb-5">Find Us On</h2>
        ) : null}

        <div
          className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5`}
        >
          {rpdata?.dbSocialMedia?.directorios.length > 0
            ? rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div className="text-center content-directorios" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
          {rpdata?.dbSocialMedia?.redes.length > 0
            ? rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div className="text-center content-directorios" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not Found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
      {/* <div className={`grid ${rpdata?.dbSocialMedia?.directorios?.length > 3 || rpdata?.dbSocialMedia?.redes?.length > 3 ? 'md:grid-cols-4' : 'md:grid-flow-col' } grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]`}> */}
    </div>
  );
}

export default Directories;
